var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "authentication-layout",
    [
      _c(
        "header",
        {
          staticClass: "header",
          attrs: { slot: "form-header" },
          slot: "form-header",
        },
        [
          _c("h2", { staticClass: "title mb-4" }, [
            _vm._v("Entre com sua conta"),
          ]),
        ]
      ),
      _c(
        "template",
        { slot: "form-content" },
        [
          _c("TheLoginForm", {
            attrs: {
              syncing: _vm.syncing,
              errorMessage: _vm.loginErrorMessage,
            },
            on: { submit: _vm.login },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "navigation" },
        [
          _c(
            "v-btn",
            {
              staticClass: "link",
              staticStyle: { "margin-bottom": "75px" },
              attrs: {
                flat: "",
                small: "",
                color: "primary",
                to: "verifica-mtr",
              },
            },
            [_vm._v("\n      Para consultar um MTR, clique aqui\n    ")]
          ),
          _c("p", { staticClass: "text-muted" }, [
            _vm._v("Ainda não possui acesso?"),
          ]),
          _c(
            "v-btn",
            {
              staticClass: "link",
              attrs: { flat: "", small: "", color: "primary", to: "cadastro" },
            },
            [_vm._v("\n      Faça seu cadastro\n    ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }