<template>
  <authentication-layout>
    <header slot="form-header" class="header">
      <h2 class="title mb-4">Entre com sua conta</h2>
    </header>

    <template slot="form-content">
      <TheLoginForm
        :syncing="syncing"
        v-on:submit="login"
        :errorMessage="loginErrorMessage"
      />
    </template>

    <template slot="navigation">

      <v-btn
        style="margin-bottom: 75px"
        flat
        small
        color="primary"
        class="link"
        :to="'verifica-mtr'">
        Para consultar um MTR, clique aqui
      </v-btn>

      <p class="text-muted">Ainda não possui acesso?</p>
      <v-btn flat small color="primary" class="link" :to="'cadastro'">
        Faça seu cadastro
      </v-btn>
    </template>
  </authentication-layout>
</template>

<script>
import AuthenticationLayout from '@/layouts/AuthenticationLayout.vue';
import TheLoginForm from '@/components/auth/TheLoginForm.vue';
import AuthService from '@/services/auth-service';

export default {
  name: 'Login',
  components: {
    AuthenticationLayout,
    TheLoginForm,
  },
  methods: {
    login(data) {
      this.syncing = true;
      this.loginErrorMessage = '';
      AuthService.login(data)
        .then((res) => {
          const { token } = res.data;
          const tokenData = this.$jwt.decode(token);
          localStorage.setItem('token', token);
          localStorage.setItem('tokenData', JSON.stringify(tokenData.payload));
          this.$ability.update(tokenData.payload.rules);
          this.$store.dispatch('auth/setCurrentUser', tokenData.payload);
          this.$router.push('/configuracoes');
        })
        .catch((err) => {
          const errMessage = err.response.data.join(',');
          this.loginErrorMessage = errMessage || 'Erro inesperado';
        })
        .finally(() => {
          this.syncing = false;
        });
    },
  },
  data() {
    return {
      syncing: false,
      loginErrorMessage: '',
    };
  },
};
</script>

<style lang="scss">
.header {
  > .title {
    font-family: 'Nexa', sans-serif !important;
    font-weight: 400;
  }
}
</style>
