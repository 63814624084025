var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "registerForm",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c("v-text-field", {
        attrs: {
          label: "CPF",
          color: "success",
          rules: _vm.cpfRules,
          mask: "###.###.###-##",
          required: "",
          error: !!_vm.errorMessage,
          box: "",
        },
        model: {
          value: _vm.formDataLogin.cpf,
          callback: function ($$v) {
            _vm.$set(
              _vm.formDataLogin,
              "cpf",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "formDataLogin.cpf",
        },
      }),
      _c("v-text-field", {
        attrs: {
          "append-icon": _vm.hidePass ? "mdi-eye" : "mdi-eye-off",
          type: _vm.hidePass ? "password" : "text",
          name: "password",
          label: "Senha",
          color: "success",
          rules: _vm.passwordRules,
          "error-messages": _vm.errorMessage,
          box: "",
        },
        on: {
          "click:append": function ($event) {
            _vm.hidePass = !_vm.hidePass
          },
          keyup: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            )
              return null
            return _vm.checkPerson.apply(null, arguments)
          },
        },
        model: {
          value: _vm.formDataLogin.password,
          callback: function ($$v) {
            _vm.$set(
              _vm.formDataLogin,
              "password",
              typeof $$v === "string" ? $$v.trim() : $$v
            )
          },
          expression: "formDataLogin.password",
        },
      }),
      _c(
        "v-layout",
        { attrs: { row: "", "justify-space-between": "" } },
        [
          _c(
            "v-btn",
            { attrs: { to: "recuperar-senha", flat: "", small: "" } },
            [
              _c("v-icon", { attrs: { left: "" } }, [
                _vm._v("mdi-lock-outline"),
              ]),
              _vm._v("\n        Esqueci minha senha\n      "),
            ],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                disabled: !_vm.valid && !_vm.errorMessage,
                loading: _vm.syncing,
              },
              on: { click: _vm.checkPerson },
            },
            [_vm._v("\n        Entrar\n      ")]
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.selectUnitDialog,
            callback: function ($$v) {
              _vm.selectUnitDialog = $$v
            },
            expression: "selectUnitDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline green darken-2 white--text",
                  attrs: { "primary-title": "" },
                },
                [_vm._v("\n        Selecione uma Pessoa\n      ")]
              ),
              _c(
                "v-card-text",
                [
                  _c("span", [
                    _vm._v(
                      "\n          Ao selecionar a Pessoa, você terá acesso aos MTR's, relatórios e todas as informações\n          a respeito da empresa.\n        "
                    ),
                  ]),
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        column: "",
                        "justify-center": "",
                        "align-center": "",
                      },
                    },
                    _vm._l(_vm.unitsToSelect, function (unit) {
                      return _c(
                        "div",
                        { key: unit.id, attrs: { xs12: "" } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(unit.name) + " - " + _vm._s(unit.cpf_cnpj)
                            ),
                          ]),
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", flat: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectUnit(unit)
                                },
                              },
                            },
                            [_c("v-icon", [_vm._v("mdi-check")])],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { flat: "" },
                      on: {
                        click: function ($event) {
                          return _vm.cancelUnitSelection()
                        },
                      },
                    },
                    [_vm._v("\n          Cancelar\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.linkToPersonDialog,
            callback: function ($$v) {
              _vm.linkToPersonDialog = $$v
            },
            expression: "linkToPersonDialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "headline green darken-2 white--text",
                  attrs: { width: "700", "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n          Vincular pessoa Física ou Jurídica\n        "
                  ),
                ]
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-stepper",
                    {
                      model: {
                        value: _vm.e1,
                        callback: function ($$v) {
                          _vm.e1 = $$v
                        },
                        expression: "e1",
                      },
                    },
                    [
                      _c(
                        "v-stepper-header",
                        [
                          _c("v-stepper-step", {
                            attrs: { complete: _vm.e1 > 1, step: "1" },
                          }),
                          _c("v-divider"),
                          _c("v-stepper-step", {
                            attrs: { complete: _vm.e1 > 2, step: "2" },
                          }),
                          _c("v-divider"),
                          _c("v-stepper-step", {
                            attrs: { complete: _vm.e1 > 3, step: "3" },
                          }),
                          _c("v-divider"),
                          _c("v-stepper-step", {
                            attrs: { complete: _vm.e1 > 4, step: "4" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-stepper-items",
                        [
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "1" } },
                            [
                              _vm._v(
                                "\n                  Ops! Parece que seu usuário ainda não possui uma Pessoa\n                  Física ou Pessoa Jurídica vinculada. Para poder utilizar\n                  o sistema, você deverá informar o CPF ou CNPJ na próxima\n                  etapa para realizar esse procedimento. A partir da vinculação,\n                  você estará apto a emitir seus manifestos e relatórios normalmente.\n                "
                              ),
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { flat: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelLinkPerson()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Cancelar\n                  "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.e1 = 2
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Prosseguir\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "2" } },
                            [
                              !_vm.noPersonToSelect
                                ? _c(
                                    "v-layout",
                                    {
                                      attrs: {
                                        "align-center": "",
                                        "justify-center": "",
                                        column: "",
                                        "fill-height": "",
                                      },
                                    },
                                    [
                                      _c("v-flex", { attrs: { xs12: "" } }, [
                                        _vm._v(
                                          "\n                    Insira o CPF ou CNPJ abaixo da Empresa ou Pessoa Física que deseja\n                    realizar a vinculação com sua conta.\n                    "
                                        ),
                                      ]),
                                      _c(
                                        "v-layout",
                                        {
                                          attrs: {
                                            "align-center": "",
                                            "justify-center": "",
                                            row: "",
                                            "fill-height": "",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-flex",
                                            { attrs: { xs6: "" } },
                                            [
                                              _c("v-text-field", {
                                                attrs: { label: "CPF ou CNPJ" },
                                                model: {
                                                  value: _vm.cpfCnpj,
                                                  callback: function ($$v) {
                                                    _vm.cpfCnpj = $$v
                                                  },
                                                  expression: "cpfCnpj",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-flex",
                                            { attrs: { xs3: "" } },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    small: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.getPerson()
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-magnify"),
                                                  ]),
                                                  _vm._v(
                                                    "\n                        Buscar\n                      "
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-layout",
                                [
                                  _c(
                                    "v-flex",
                                    _vm._l(_vm.peopleToLink, function (person) {
                                      return _c(
                                        "div",
                                        { key: person.id, attrs: { xs12: "" } },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(person.name) +
                                                " - " +
                                                _vm._s(person.cpf_cnpj)
                                            ),
                                          ]),
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: { top: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          "v-btn",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: "",
                                                                flat: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.linkPerson(
                                                                      person
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c("v-icon", [
                                                              _vm._v(
                                                                "mdi-check"
                                                              ),
                                                            ]),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Vincular Pessoa Física ou Jurídica"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _vm.noPersonToSelect
                                ? _c(
                                    "v-layout",
                                    [
                                      _c("v-flex", [
                                        _c("div", { attrs: { xs12: "" } }, [
                                          _vm._v(
                                            "\n                      Ainda não existe uma Pessoa Física ou Jurídica cadastrada\n                      com o CPF/CNPJ informado. Na próxima etapa, informe alguns\n                      dados para que possamos realizar o cadastro\n                      e vincula-la ao seu usuário.\n                    "
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.noPersonToSelect
                                ? _c(
                                    "v-layout",
                                    { attrs: { "justify-center": "" } },
                                    [
                                      _c("v-btn", { attrs: { flat: "" } }, [
                                        _vm._v("Cancelar"),
                                      ]),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              _vm.e1 = 3
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    Prosseguir\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "3" } },
                            [
                              _vm.e1 == 3
                                ? _c("create-entity-form", {
                                    attrs: { formEntity: _vm.formData },
                                    on: { formIsValid: _vm.entityFormIsValid },
                                  })
                                : _vm._e(),
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { flat: "" },
                                      on: { click: _vm.cancelLinkPerson },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Cancelar\n                  "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        color: "primary",
                                        disabled: !_vm.entityFormValid,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.savePerson()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Salvar\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-stepper-content",
                            { attrs: { step: "4" } },
                            [
                              _vm._v(
                                "\n\n                Tudo certo, seu usuário foi vinculado com sucesso a Pessoa Física/Pessoa Jurídica\n                informada. A partir de agora, você poderá entrar no sistema com a senha informada\n                e emitir seus manifestos de transporte de resíduos e/ou relatórios.\n\n                "
                              ),
                              _c(
                                "v-layout",
                                { attrs: { "justify-center": "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { flat: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelLinkPerson()
                                        },
                                      },
                                    },
                                    [_vm._v("Fechar")]
                                  ),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.submit()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                    Realizar Login\n                  "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }