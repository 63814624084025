<template>
  <v-form ref="registerForm" lazy-validation v-model="valid">
    <v-text-field
      label="CPF"
      color="success"
      v-model.trim="formDataLogin.cpf"
      :rules="cpfRules"
      mask="###.###.###-##"
      required
      :error="!!errorMessage"
      box
    />

    <v-text-field
      :append-icon="hidePass ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="hidePass = !hidePass"
      :type="hidePass ? 'password' : 'text'"
      name="password"
      v-model.trim="formDataLogin.password"
      label="Senha"
      color="success"
      :rules="passwordRules"
      @keyup.enter="checkPerson"
      :error-messages="errorMessage"
      box
    />

    <v-layout row justify-space-between>
      <v-btn
        :to="'recuperar-senha'"
        flat
        small
      >
        <v-icon left>mdi-lock-outline</v-icon>
        Esqueci minha senha
      </v-btn>

      <v-btn
        color="success"
        :disabled="!valid && !errorMessage"
        :loading="syncing"
        @click="checkPerson"
      >
        Entrar
      </v-btn>
    </v-layout>

<!--Selecionar Unidade-->
    <v-dialog
    v-model="selectUnitDialog"
    width="500"
    persistent
    >
    <v-card>
      <v-card-title
        class="headline green darken-2 white--text"
        primary-title
      >
        Selecione uma Pessoa
      </v-card-title>

      <v-card-text>
        <span>
          Ao selecionar a Pessoa, você terá acesso aos MTR's, relatórios e todas as informações
          a respeito da empresa.
        </span>
        <v-layout column justify-center align-center>
          <div xs12 v-for="unit in unitsToSelect" :key="unit.id">
            <span>{{unit.name}} - {{unit.cpf_cnpj}}</span>
            <v-btn icon flat @click="selectUnit(unit)">
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </div>
        </v-layout>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          flat
          @click="cancelUnitSelection()"
        >
          Cancelar
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
<!---->

    <!--Selecionar Pessoa-->
    <v-dialog
      v-model="linkToPersonDialog"
      width="500"
      persistent
    >
      <v-card>
        <v-card-title
          class="headline green darken-2 white--text"
          width="700"
          primary-title
        >
          Vincular pessoa Física ou Jurídica
        </v-card-title>

        <v-card-text>

          <v-stepper v-model="e1">
            <v-stepper-header>
              <v-stepper-step :complete="e1 > 1" step="1"></v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 2" step="2"></v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 3" step="3"></v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="e1 > 4" step="4"></v-stepper-step>

            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                  Ops! Parece que seu usuário ainda não possui uma Pessoa
                  Física ou Pessoa Jurídica vinculada. Para poder utilizar
                  o sistema, você deverá informar o CPF ou CNPJ na próxima
                  etapa para realizar esse procedimento. A partir da vinculação,
                  você estará apto a emitir seus manifestos e relatórios normalmente.
                <v-layout justify-center>
                  <v-btn flat
                         @click="cancelLinkPerson()"
                  >
                    Cancelar
                  </v-btn>

                  <v-btn
                    color="primary"
                    @click="e1 = 2">
                    Prosseguir
                  </v-btn>
                </v-layout>

              </v-stepper-content>

              <v-stepper-content step="2">

                <v-layout align-center justify-center column fill-height
                          v-if="!noPersonToSelect">
                  <v-flex xs12>
                    Insira o CPF ou CNPJ abaixo da Empresa ou Pessoa Física que deseja
                    realizar a vinculação com sua conta.
                    </v-flex>
                  <v-layout  align-center justify-center row fill-height>
                    <v-flex xs6>
                      <v-text-field
                        v-model="cpfCnpj"
                        label="CPF ou CNPJ"
                      />
                    </v-flex>
                    <v-flex xs3>
                      <v-btn
                        color="primary"
                        small
                        @click="getPerson()">
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-flex>
                  </v-layout>

                </v-layout>

                <v-layout>
                  <v-flex>
                    <div xs12 v-for="person in peopleToLink" :key="person.id">
                      <span>{{person.name}} - {{person.cpf_cnpj}}</span>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn icon flat
                                 v-on="on"
                                 @click="linkPerson(person)">
                            <v-icon>mdi-check</v-icon>
                          </v-btn>
                        </template>
                        <span>Vincular Pessoa Física ou Jurídica</span>
                      </v-tooltip>
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout v-if="noPersonToSelect">
                  <v-flex>
                    <div xs12>
                      Ainda não existe uma Pessoa Física ou Jurídica cadastrada
                      com o CPF/CNPJ informado. Na próxima etapa, informe alguns
                      dados para que possamos realizar o cadastro
                      e vincula-la ao seu usuário.
                    </div>
                  </v-flex>
                </v-layout>

                <v-layout justify-center v-if="noPersonToSelect">
                  <v-btn flat>Cancelar</v-btn>

                  <v-btn
                    color="primary"
                    @click="e1 = 3"
                  >
                    Prosseguir
                  </v-btn>
                </v-layout>

              </v-stepper-content>

              <v-stepper-content step="3">
                <create-entity-form
                  v-if="e1 == 3"
                  :formEntity="formData"
                  @formIsValid="entityFormIsValid"
                />

                <v-layout justify-center>

                  <v-btn
                    flat
                    @click="cancelLinkPerson"
                  >
                    Cancelar
                  </v-btn>

                  <v-btn
                    color="primary"
                    :disabled="!entityFormValid"
                    @click="savePerson()"
                  >
                    Salvar
                  </v-btn>

                </v-layout>

              </v-stepper-content>

              <v-stepper-content step="4">

                Tudo certo, seu usuário foi vinculado com sucesso a Pessoa Física/Pessoa Jurídica
                informada. A partir de agora, você poderá entrar no sistema com a senha informada
                e emitir seus manifestos de transporte de resíduos e/ou relatórios.

                <v-layout justify-center>
                  <v-btn flat @click="cancelLinkPerson()">Fechar</v-btn>

                  <v-btn
                    color="primary"
                    @click="submit()"
                  >
                    Realizar Login
                  </v-btn>
                </v-layout>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>

        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import masks from '@/utils/masks';
import validationRules from '@/mixins/validation-rules';
import AuthService from '@/services/auth-service';
import personService from '@/services/person-service';
import CreateEntityForm from '@/components/person/CreateEntityForm.vue';

export default {
  name: 'TheLoginForm',
  props: ['syncing', 'errorMessage'],
  data() {
    return {
      formData: {
        postalcode: '',
        cpf_cnpj: '',
        profile: [],
      },
      formDataLogin: {},
      entityFormValid: false,
      e1: 0,
      valid: true,
      hidePass: true,
      passwordRules: [validationRules.required],
      cpfRules: [validationRules.required, validationRules.cpf],
      unitsToSelect: [],
      selectUnitDialog: false,
      linkToPersonDialog: false,
      peopleToLink: [],
      cpfCnpj: '',
      validationRules,
      masks,
      noPersonToSelect: false,
    };
  },
  mixins: [validationRules],
  components: {
    CreateEntityForm,
  },
  methods: {
    cancelLinkPerson() {
      this.e1 = 1;
      this.linkToPersonDialog = false;
      this.noPersonToSelect = false;
    },
    async getPerson() {
      const response = await personService.searchForLink({ q: this.cpfCnpj });
      if (response.data.length > 0) {
        this.peopleToLink = response.data;
      } else {
        this.noPersonToSelect = true;
      }
    },
    savePerson() {
      const payload = {
        cpf: this.formDataLogin.cpf,
        cpf_cnpj: this.formData.cpf_cnpj,
        name: this.formData.name,
        profile: this.formData.profile,
        address_attributes: {
          complement: this.formData.address_details,
          neighborhood: this.formData.neighbourhood,
          number: this.formData.number,
          street: this.formData.street,
          zip_code: this.formData.postalcode,
          city_id: this.formData.city.id,
          federal_unit_id: this.formData.state.id,
        },
      };

      personService
        .createPerson(payload)
        .then(({ data }) => {
          this.e1 = 4;
          this.formDataLogin.person_id = data.id;
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-ckeck',
          });
        });
    },
    submit() {
      if (this.$refs.registerForm.validate()) {
        this.$emit('submit', this.formDataLogin);
      }
    },
    selectUnit(unit) {
      this.selectUnitDialog = false;
      this.formDataLogin.person_id = unit.id;
      this.submit();
    },
    linkPerson(person) {
      const payload = {
        cpf: this.formDataLogin.cpf,
        role: 'company',
        person_id: person.id,
      };
      personService
        .linkEmployee(payload)
        .then(() => {
          this.selectUnitDialog = false;
          this.formDataLogin.person_id = person.id;
          this.submit();
        })
        .catch((err) => {
          this.$store.dispatch('application/toggleSnackbar', {
            show: true,
            timeout: 6000,
            color: 'error',
            message: err.response.data.join(', '),
            messageIcon: 'mdi-ckeck',
          });
        });
    },
    cancelUnitSelection() {
      this.selectUnitDialog = false;
      this.linkToPersonDialog = false;
    },
    checkPerson() {
      AuthService
        .checkForUnits(this.formDataLogin.cpf)
        .then((response) => {
          if (response.data.length === 1) {
            this.formDataLogin.person_id = response.data[0].id;
            this.submit();
          } else {
            this.unitsToSelect = response.data;
            this.selectUnitDialog = true;
          }
        })
        .catch((err) => {
          if (err.response.status === 403) {
            this.linkToPersonDialog = true;
          } else {
            this.$store.dispatch('application/toggleSnackbar', {
              show: true,
              timeout: 6000,
              color: 'error',
              message: err.response.data.join(', '),
              messageIcon: 'mdi-ckeck',
            });
          }
        });
    },
    entityFormIsValid(value) {
      this.entityFormValid = value;
    },
  },
};
</script>

